body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.std-link{
  color: #0000008c;
  text-decoration: none;
}

.navbar-nav .nav-item .nav-link, .navbar-nav .nav-link {
  text-transform: uppercase;
}

.navbar-nav .nav-link.active, .navbar-nav .show>.nav-link{
  color: #B14A31;
}

.nav-link:focus, .nav-link:hover, .std-link:hover{
  color: #B14A31;
}

.navbar-nav .dropdown-item.active, .navbar-nav .dropdown-item:active{
  background-color: #dee2e6;
}

.internal-page .title-block{
  width: 100%;
  height: 200px; 
  text-align: center;
  margin-bottom: 50px;
}

.internal-page .title-block h1{
  padding-top: 30px;
  color: #ffffff;
  text-transform: uppercase;
}

.internal-page .title-block .title-buttons{
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.std-button{
  display: block;
  background-color: #ffffff;
  color: #B14A31;
  border-color: #B14A31;
  text-decoration: none;
}

.std-button:hover, .std-button:active{
  color: #ffffff !important;
  background-color: #B14A31 !important;
  border-color: #B14A31 !important;
}

.import-tb {
  text-align: center;
}

.import-tb thead tr th{
  /* padding: 0; */
}

.import-tb thead tr th .th-styled{
  background-color: #198754;
  color: #ffffff;
}